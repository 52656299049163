html,
body {
  height: 100%;
}

body {
    @include transition(ease .1s);
    @include fadeInAnimation('fadeInAnimation');
    color: var(--text-color);
    font-family: proxima-nova,sans-serif;
    font-weight: 400;
    font-size: 16px;
    direction: ltr;
    letter-spacing: normal;
}

a {
    color: $blue-05;
    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--secondary-color);
        outline: none;
        box-shadow: none;
    }
}

.form-control,
.form-select {
  &.form-control-sm,
  &.form-select-sm {
    border-radius: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}
