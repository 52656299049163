.form-control,
.form-select {
  border-color: #DEE1E4;
  border-radius: 8px;
}

.form-control.form-control-xl {
  height: 54px;
}

.form-label.form-label-xl {
  font-size: 16px;
  font-weight: 700;
}

.form-select-xl {
  height: 54px;
  background-color: #F8F8F8;

  &:hover{
    border-color: darken(#DEE1E4, 10%);
  }

  &:focus {
    background-color: #F8F8F8;
    border-color: darken(#DEE1E4, 20%);
    color: #333;
    box-shadow: none;
  }
}

.input-group {

  .btn {
    border-radius: 8px;
  }
  .clear {
    color: #B4B8BD;
  }

  &.merged {
    border: 1px solid #DEE1E4;
    border-radius: 8px;
    transition: border-color 0.3s;

    &:hover{
      border-color: darken(#DEE1E4, 10%);
    }

    &:focus-within {
      border-color: darken(#DEE1E4, 20%);
    }

    .form-control {
      border: 0 none;

      &:focus {
        box-shadow: none;
        border: 0 none;
        color: #333;
      }
    }


    .btn {
      border: 0 none;
      font-size: 16px;

      &:hover {
        background-color: #fff;
        color: darken(#B4B8BD, 10%);
      }

      &:active {
        border: 0 none;
      }
    }
  }
}

$check-group-offset: 36px;

.check-group {
  .form-check {
    padding-left: $check-group-offset;
  }

  .form-check-input {
    height: 23px;
    width: 23px;
    margin-left: -$check-group-offset;
  }

  .form-check-label {
    line-height: 1.1em;
  }
}
