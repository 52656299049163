.page-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: $blue-02;
  gap: 16px;
  padding: 44px 16px;

  .inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 480px;

    > p {
      font-size: 16px;
      line-height: 24px;
    }
  }

}
